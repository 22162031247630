import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/OfficialPumpETH',
    icon: Twitter,
  },
  // {
  //   name: 'Reddit',
  //   href: 'https://www.reddit.com/r/TheRealPumpETH/',
  //   icon: Reddit,
  // },
  {
    name: 'Telegram',
    href: 'https://t.me/OfficialPumpETH',
    icon: Telegram,
  },
  // {
  //   name: 'Discord',
  //   href: 'https://discord.gg/KxaHpHbQy4',
  //   icon: Discord,
  // },
]



export default function Footer() {
  return (
    <div>
      <footer className="bg-blue-50">
        <div className="py-12 container md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            {socials.map((item) => (
              <a key={item.name} href={item.href} className="text-blue-400 hover:text-blue-600">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">&copy; 2021 PumpETH.finance - All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>

  )
}